// Libraries
import PropTypes from 'prop-types';

const ModalRoot = ({ activeModal }) => {
  if (!activeModal) return null;

  return activeModal;
};

ModalRoot.propTypes = {
  activeModal: PropTypes.node,
  setActiveModal: PropTypes.func.isRequired,
};

ModalRoot.defaultProps = {
  activeModal: null,
};

export default ModalRoot;
