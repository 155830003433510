// TODO: Refactor existing modals to use this API and move to common

// Libraries
import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

// Contexts
import ModalContext from 'contexts/Modal/ModalContext';

// Components
import BaseModal from 'ideation/components/base/BaseModal';

// TODO: Remove 'skipFocusTrap' prop after bulk options modal refactor
const Modal = ({ isOpen, onClose, children, skipFocusTrap, className }) => {
  const { setActiveModal } = useContext(ModalContext);
  useEffect(() => {
    setActiveModal(
      isOpen ? (
        <BaseModal onClose={onClose} skipFocusTrap={skipFocusTrap} className={className}>
          {children}
        </BaseModal>
      ) : null
    );
  }, [isOpen]);
  return null;
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

Modal.defaultProps = {
  isOpen: PropTypes.bool.isRequired,
  skipFocusTrap: false,
  className: null,
};

export default Modal;
