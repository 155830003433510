// Libraries
import { useEffect } from 'react';
import { createFocusTrap } from 'focus-trap';

export const useFocusTrap = (trapFocus, focusTrapRef, initialFocus) => {
  useEffect(() => {
    let focusTrap;
    const focusTrapElement = focusTrapRef.current;
    if (trapFocus && focusTrapElement) {
      focusTrap = createFocusTrap(focusTrapElement, { initialFocus });
      focusTrap.activate();
    }
    return () => {
      if (focusTrap) {
        focusTrap.deactivate();
      }
    };
  }, []);
};
