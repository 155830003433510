// Libraries
import { useEffect } from 'react';

export const useKeyListener = (key, onKeyPress) => {
  const keyListener = (event) => {
    if (event.key === key) {
      onKeyPress(event);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', keyListener);
    return () => window.removeEventListener('keydown', keyListener);
  });
};
