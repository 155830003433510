// TODO: Refactor existing modals to use this API and move to common

// Libraries
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Hooks
import { useScrollDisable } from 'ideation/hooks/base/useScrollDisable';
import { useKeyListener } from 'common/hooks/useKeyListener';
import { useFocusTrap } from 'ideation/hooks/base/useFocusTrap';

// Enums
import Keys from 'common/enums/Key';

// TODO: Remove 'skipFocusTrap' prop after bulk options modal refactor
const BaseModal = ({ children, onClose, className, skipFocusTrap }) => {
  const modalRef = useRef(null);

  useScrollDisable();
  useKeyListener(Keys.ESCAPE, onClose);
  useFocusTrap(!skipFocusTrap, modalRef);

  return (
    <div className="ideation-modal" ref={modalRef}>
      <div
        className={classNames('modal', 'is-active', className)}
        aria-modal
        aria-hidden
        tabIndex={-1}
        role="dialog"
      >
        <div className="modal-background" />
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="modal-exit" onClick={onClose}>
              <span className="modal-exit-icon is-secondary-color" aria-hidden="true">
                &times;
              </span>
            </button>
          </div>
          <div className="modal-body">{children}</div>
        </div>
      </div>
    </div>
  );
};

BaseModal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  className: PropTypes.string,
  skipFocusTrap: PropTypes.bool,
};

BaseModal.defaultProps = {
  onClose: () => {},
  className: null,
  skipFocusTrap: false,
};

export default BaseModal;
