// Libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Root
import ModalRoot from 'contexts/Modal/ModalRoot';

// Context
import ModalContext from 'contexts/Modal/ModalContext';

const ModalProvider = ({ children }) => {
  const [activeModal, setActiveModal] = useState();

  return (
    <ModalContext.Provider value={{ activeModal, setActiveModal }}>
      {children}
      <ModalRoot activeModal={activeModal} setActiveModal={setActiveModal} />
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ModalProvider;
