// Libraries
import { useEffect } from 'react';

export const useScrollDisable = () => {
  const htmlClassList = window.document.documentElement.classList;

  useEffect(() => {
    if (!htmlClassList) return null;
    htmlClassList.add('is-clipped');
    return () => {
      htmlClassList.remove('is-clipped');
    };
  }, []);
};
